.container {
  width: 300px;
  height: 424px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  position: relative;
  border: 2px solid #FBD51A;
  position: relative;
  }

  .couponOverlayContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    opacity: 0.9;
    background-color: #212121;
    background-image: url("../../assets/couponbg.png");
     background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
  }

  .couponOverlay {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background: linear-gradient(180deg, #FFF5C6 0%, #FFF 100%);
  }

  .base {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    cursor: grabbing;
    text-align: center;
   
  }


  .particles {
    position: absolute;
    top: 20;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.priceText {
    color: #2A2A2A;
    font-size: 40px;
    font-family: Proxima Nova A;
    font-weight: 400;
    text-align: center;
    position: relative;
    width: 240px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badge {
    background-color: #2A2A2A;
    text-align: center;
    color: #fff;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 12px;
    margin: auto;
}

.betterLuck {
  display: flex;
  flex-direction: column;
  gap: 20px;
  span {
    color: #2A2A2A;
    text-align: center;
    font-family: Proxima Nova A;
    font-size: 30px;
    font-weight: 400;
    display: inline-block;
    width: 200px;
  }
}