.container {
    width: 100%;
    height: 100%;
    background-color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    // position: absolute;
    // top: 80px;
    // right: 20px;
  }
  
  .main {
    display: flex;
    justify-content: center;
    background-color: transparent;
  }
  
  .welcomeScreen {
    background: #fff;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 144px;
  
  //linear-gradient(90deg, #86678f 0%, #b485d2 31.16%, #d0a9ff 49.39%, #a88cff 64.18%, #7d6c97 100%)
  
    p {
      font-family: Proxima Nova A_Bold;
      font-size: 24px;
      font-weight: 600;
    }
  }
  
  .welcomeScreenImage {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contentContainer {
    width: 100%;
    min-height: 100%;
    // background-color: #212121;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding: 24px;
    // // background-image: url("../../assets/couponbg.png");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
  }
  
  .topIconsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .couponLogoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    // margin-bottom: 10px;
  }
  
  .brandLogo {
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9EBA2;
    stroke-width: 1.304px;
    stroke: #FFF;
    overflow: hidden;
    border: 1.3px solid #FFFFFF
  }
  
  .brandLogo img {
    border-radius: 50%;
  }
  
  .scratchText {
    color: #FFF;
    font-size: 24px;
    font-family: Proxima Nova A_Bold;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
    // margin-top: 28px;
    // margin-bottom: 40px;
  }