.mainContainer {
    width: 100%;
    background: #FDFDFD;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
  }
  
  .container {
    background: #FDFDFD;
    padding: 24px 16px 91px 16px;
    // margin: auto;
  }
  
  .cardGrid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-items: center;
  }
  
  .dealCard {
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .dealCard img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    vertical-align: top;
  }
  
  .dealCardDescription {
    flex-grow: 1;
    padding: 16px;
    background: linear-gradient(132deg, #F2F5F0 37.25%, #E8F7FF 85.4%);
    gap: 10px;
  }
  
  .dealTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #212121;
  }
  
  .dealFooterText {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #474747;
  }
  
  // @media screen and (min-width: 600px) {
  //   .cardGrid {
  //     grid-template-columns: repeat(1, 1fr);
  //   }
  // }
  
  // @media screen and (min-width: 768px) {
  //   .cardGrid {
  //     grid-template-columns: repeat(2, 1fr);
  //   }
  
  //   .dealCard {
  //     width: 46.5vw;
  //   }
  // }
  
  // @media screen and (min-width: 1024px) {
  //   .cardGrid {
  //     grid-template-columns: repeat(3, 1fr);
  //   }
  
  //   .dealCard {
  //     width: 31vw;
  //   }
  // }