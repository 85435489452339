.container {
    width: 300px;
    height: 424px;
    border-radius: 24px;
    background-color: #fff;
}
.contentContainer {
    width: 300px;
    height: 424px;
    border-radius: 24px;
    // border-radius: 12px;
    // background-color: #FBD51A;
    // background: linear-gradient(116deg, #D8BB3A 35.37%, rgba(255, 243, 191, 0.00) 161.4%);
}

.headerText {
    color: #212121;
    margin: 10px 0;
    font-family: Proxima Nova A;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}