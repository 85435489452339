body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */
/* @import url('https://fonts.cdnfonts.com/css/lato'); */
/* For Webkit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 0px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(
    188,
    186,
    186
  ); /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Set the border-radius to round the corners */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the scrollbar track */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Proxima Nova A - Bold Italic */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Bold.otf")
    format("opentype");
  font-weight: bold;
  font-style: italic;
}

/* Proxima Nova A - Extrabold Italic */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Extrabold It.otf")
    format("opentype");
  font-weight: 800; /* Extrabold */
  font-style: italic;
}

/* Proxima Nova A - Extrabold */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Extrabold.otf")
    format("opentype");
  font-weight: 800; /* Extrabold */
  font-style: normal;
}

/* Proxima Nova A - Italic */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A It.otf")
    format("opentype");
  font-weight: normal;
  font-style: italic;
}

/* Proxima Nova A - Light Italic */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Light It.otf")
    format("opentype");
  font-weight: 300; /* Light */
  font-style: italic;
}

/* Proxima Nova A - Light */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Light.otf")
    format("opentype");
  font-weight: 300; /* Light */
  font-style: normal;
}

/* Proxima Nova A - Medium Italic */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Medium It.otf")
    format("opentype");
  font-weight: 500; /* Medium */
  font-style: italic;
}

/* Proxima Nova A - Medium */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Medium.otf")
    format("opentype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

/* Proxima Nova A - Semibold Italic */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Semibold It.otf")
    format("opentype");
  font-weight: 600; /* Semibold */
  font-style: italic;
}
/* Proxima Nova A - Semibold Italic */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark\ Simonson\ -\ Proxima\ Nova\ A\ Medium.otf")
    format("opentype");
  font-weight: 400; /* Semibold */
  /* font-style: italic; */
}

/* Proxima Nova A - Semibold */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Semibold.otf")
    format("opentype");
  font-weight: 600; /* Semibold */
  font-style: normal;
}

/* Proxima Nova A - Thin Italic */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Thin It.otf")
    format("opentype");
  font-weight: 100; /* Thin */
  font-style: italic;
}

/* Proxima Nova A - Thin */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A Thin.otf")
    format("opentype");
  font-weight: 100; /* Thin */
  font-style: normal;
}

/* Proxima Nova A - Regular */
@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/Fonts/Mark Simonson - Proxima Nova A.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

/*  ABeeZee */

@font-face {
  font-family: "ABeeZee";
  src: url("./assets/Fonts/ABeeZee/ABeeZee-Regular.ttf") format("opentype");
  font-weight: 400;
}

/* Mulish */

@font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* @font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Medium.ttf") format("opentype");
  font-weight: 400;
} */

/* @font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Bold.ttf") format("opentype");
  font-weight: 400;
} */

@font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Regular.ttf") format("opentype");
  font-weight: 500;
}

/* @font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Medium.ttf") format("opentype");
  font-weight: 500;
} */

/* @font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Bold.ttf") format("opentype");
  font-weight: 500;
} */

@font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Regular.ttf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Medium.ttf") format("truetype");
  font-weight: 600;
}

/* @font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Bold.ttf") format("opentype");
  font-weight: 600;
} */

@font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Regular.ttf") format("opentype");
  font-weight: 700;
}

/* @font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Medium.ttf") format("opentype");
  font-weight: 700;
} */

@font-face {
  font-family: "Mulish";
  src: url("./assets//Fonts/Mulish/Mulish-Bold.ttf") format("opentype");
  font-weight: 700;
}


/* Outfit */

@font-face {
  font-family: "Outfit";
  src: url("./assets/Fonts/Outfit/Outfit-Regular.ttf") format("opentype");
  font-weight: 400;
}

/* @font-face {
  font-family: "Outfit";
  src: url("./assets/Fonts/Outfit/Outfit-Medium.ttf") format("opentype");
  font-weight: 400;
} */

@font-face {
  font-family: "Outfit";
  src: url("./assets/Fonts/Outfit/Outfit-Bold.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/Fonts/Outfit/Outfit-Regular.ttf") format("opentype");
  font-weight: 600;
}

/* @font-face {
  font-family: "Outfit";
  src: url("./assets/Fonts/Outfit/Outfit-Medium.ttf") format("opentype");
  font-weight: 600;
} */

@font-face {
  font-family: "Outfit";
  src: url("./assets/Fonts/Outfit/Outfit-Bold.ttf") format("opentype");
  font-weight: 600;
}

/* Montserrat */

/* @font-face {
  font-family: "Montserrat";
  src: url("./assets/Fonts/Montserrat/Montserrat-Thin.ttf") format("opentype");
  font-weight: 600;
} */

@font-face {
  font-family: "Montserrat";
  src: url("./assets/Fonts/Montserrat/Montserrat-Regular.ttf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/Fonts/Montserrat/Montserrat-Medium.ttf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/Fonts/Montserrat/Montserrat-SemiBold.ttf") format("opentype");
  font-weight: 600;
}

/* @font-face {
  font-family: "Montserrat";
  src: url("./assets/Fonts/Montserrat/Montserrat-Bold.ttf") format("opentype");
  font-weight: 600;
} */

/* Inter */

/* @font-face {
  font-family: "Inter";
  src: url("./assets/Fonts/Inter/Inter-Thin.ttf") format("opentype");
  font-weight: 400;
} */

/* @font-face {
  font-family: "Inter";
  src: url("./assets/Fonts/Inter/Inter-Medium.ttf") format("opentype");
  font-weight: 400;
} */

@font-face {
  font-family: "Inter";
  src: url("./assets/Fonts/Inter/Inter-Regular.ttf") format("opentype");
  font-weight: 400;
}

/* @font-face {
  font-family: "Inter";
  src: url("./assets/Fonts/Inter/Inter-Bold.ttf") format("opentype");
  font-weight: 400;
} */

/* @font-face {
  font-family: "Inter";
  src: url("./assets/Fonts/Inter/Inter-Thin.ttf") format("opentype");
  font-weight: 500;
} */

/* @font-face {
  font-family: "Inter";
  src: url("./assets/Fonts/Inter/Inter-Medium.ttf") format("opentype");
  font-weight: 500;
} */

@font-face {
  font-family: "Inter";
  src: url("./assets/Fonts/Inter/Inter-Regular.ttf") format("opentype");
  font-weight: 500;
}

/* @font-face {
  font-family: "Inter";
  src: url("./assets/Fonts/Inter/Inter-Bold.ttf") format("opentype");
  font-weight: 500;
} */

/* Lato */
@font-face {
  font-family: "Lato";
  src: url("./assets/Fonts/lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/Fonts/lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/Fonts/lato/Lato-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Lato";
  src: url("./assets/Fonts/lato/Lato-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/Fonts/lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
}