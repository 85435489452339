.mainContainer {
  width: 100%;
  height: 100%;
    background: #FDFDFD;
    font-family: Proxima Nova A;
    // position: fixed;
    // top: 25px;
    // right:  25px;
    z-index: 5;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  .dealImageContainer {
    width: 100%;
  }
  .dealImageContainer img {
    width: 100%;
    object-fit: fill;
  }
  .mainContent {
    padding: 16px 16px 0;
  }
  .DealTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #212121;
    padding: 16px 0 0;
  }
  .dealSourceText {
    font-family: Proxima Nova A;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
    padding: 16px 0;
  }
  .dealValidityContainer {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .dealInfoIcon {
    display: flex;
    align-items: center;
  }
  .dealValidityText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #2A2A2A;
  }
  .horizontalLineContainer {
    width: 99%;
    margin: auto;
    overflow: hidden;
  }
  .horizontalLine {
    margin: 16px -10.5px;
    height: 1px;
    background: linear-gradient(to right, transparent 50%, #929094 50%), linear-gradient(to right, #FFFFFF, #FFFFFF);
    background-size: 15.3px 1px, 100% 1px;
  }
  .dealsTermsConditionLabel {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 2px;
    text-align: left;
    color: #005CBD;
    padding: 12px 0px 12px 0px;
  }
  .dealsTermsConditionListBox {
    margin: 0 0 0 -15px;
  }
  .dealsTermsConditionList {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #212121;
    padding: 6px 0;
  }

  .termsAndConditions {
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    padding: 0.2px 12px;
    border-radius: 8px;
    overflow-y: auto; /* Ensure scroll if content exceeds container */
    word-wrap: break-word; /* Prevent text overflow */
    max-height: 300px; /* Optional: to limit the height and make it scrollable */
    text-align: left;
    strong, b {
      font-weight: bold;
    }
    
    em, i {
      font-style: italic;
    }
    
    ul, ol {
      padding-left: 20px; /* Ensure proper indentation for lists */
    }
  
    li {
      margin-bottom: 10px; /* Add some space between list items */
    }
  }
  
  .dealsTermsConditionListBox {
    margin: 0 0 0 -15px;
    overflow-y: auto; /* Allow scrolling for long lists */
    max-height: 300px; /* Limit height of list if it's too long */
  }
  

.termsAndConditions > p {
  text-align: left;
  background-color: inherit;
  font-family: Proxima Nova A;
 font-size: 14px;
 font-weight: 400;
 line-height: 20px;
}

  .scratchCardsCarousel {
    padding: 16px 0 16px 16px;
  }
  .dealsContainer {
    background-color: #FFFFFF;
  }